<template>
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.9987 15.1663C4.3168 15.1663 1.33203 12.1815 1.33203 8.49967C1.33203 4.81777 4.3168 1.83301 7.9987 1.83301C11.6806 1.83301 14.6654 4.81777 14.6654 8.49967C14.6654 12.1815 11.6806 15.1663 7.9987 15.1663ZM7.33203 7.83301V11.833H8.66536V7.83301H7.33203ZM7.33203 5.16634V6.49967H8.66536V5.16634H7.33203Z"
      fill="#DC524B"
    />
  </svg>
</template>
